import Home from "./Home"

const App = () => {
  
  return (
    <Home />
  )
}

export default App
